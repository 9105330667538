import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import {endpoint} from './App';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(true)


  function generateDUAid() {
    return 'DUAID-' + crypto.randomUUID();
}

  useEffect(() => {
    localStorage.setItem("isDarkMode", isDarkMode)
    document.body.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode])

  useEffect(() => {
    const DUAID = localStorage.getItem('DUAID');
    const token = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    if (!DUAID) {
      localStorage.setItem('DUAID', generateDUAid());
    }
    if (token) {
      // Retrieve user info from local storage
      setUser(storedUser);

      // Optionally verify the token or re-fetch the user data from the server
      axios.get(`${endpoint}/user`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        localStorage.setItem('user', JSON.stringify(response.data));
                setUser(JSON.parse(localStorage.getItem('user')))
      })
      .catch(error => {
        console.error('Error fetching user', error);
        // Optionally, handle the error (e.g., remove the token, redirect to login)
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      })
      .finally(() => {
        // We comment it out because the only time 
        // this would reset is when page is refreshed, which we then need the loading for post load
        // setLoading(false);
      });
      
    } else {
      // setLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, loading, setLoading, isDarkMode, setIsDarkMode}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);