import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {endpoint} from './App';

const industryIcons = {
    'All': <i className="fas fa-globe" style={{ fontSize: '1.2em' }}></i>,
    'Medical': <i className="fas fa-stethoscope" style={{ fontSize: '1.2em' }}></i>,
    'Technology': <i className="fas fa-microchip" style={{ fontSize: '1.2em' }}></i>,
    'Education': <i className="fas fa-graduation-cap" style={{ fontSize: '1.2em' }}></i>,
    'Finance': <i className="fas fa-dollar-sign" style={{ fontSize: '1.2em' }}></i>,
    'Retail': <i className="fas fa-shopping-cart" style={{ fontSize: '1.2em' }}></i>,
    'Hospitality': <i className="fas fa-concierge-bell" style={{ fontSize: '1.2em' }}></i>,
    'Other': <i className="fas fa-ellipsis-h" style={{ fontSize: '1.2em' }}></i>,
};

const FilterPane = ({ selectedIndustry, setSelectedIndustry, filterCountMap, filterPaneLoading }) => {
    const industries = ['All', 'Technology', 'Medical', 'Education', 'Finance', 'Retail', 'Hospitality', 'Other'];

    if (filterPaneLoading) {
        return <div className = "filter-pane"></div>
    }

    return (
        <div className="filter-pane">
            <h3>Industries</h3>
            {industries.map(industry => (
                <div 
                    key={industry} 
                    className={`filter-option ${selectedIndustry === industry ? 'selected' : ''}`}
                    style={{ 
                        color: selectedIndustry === industry ? 'black' : '#7f8c8d', // Black for selected, gray for unselected
                        fontWeight: selectedIndustry === industry ? 'bold' : 'normal', // Bold for selected
                    }}
                    onClick={() => {
                        setSelectedIndustry(industry);      
                        localStorage.setItem("pageNumber", 0)                     
                        localStorage.setItem("selectedIndustry", industry);
                    }} // Set selected industry on click
                >
                    <span className="hover-bar">| </span>
                    {industryIcons[industry]} {industry} ({filterCountMap[industry]})
                </div>
            ))}
        </div>
    );
};

export default FilterPane;