// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-container {
  padding-top: var(--header-height);
  background-color: var(--dropdown-bg-color); /* Use dropdown background color variable */
  color: var(--nav-link-color);
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,0CAA0C,EAAE,2CAA2C;EACvF,4BAA4B;AAC9B","sourcesContent":[".app-container {\n  padding-top: var(--header-height);\n  background-color: var(--dropdown-bg-color); /* Use dropdown background color variable */\n  color: var(--nav-link-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
