import React, {useState, useEffect} from 'react';
import { Link, redirect, useNavigate} from 'react-router-dom';
import SortDropdown from './SortDropdown'; // Adjust the path if necessary
import { useAuth } from './AuthContext';
import axios from 'axios';
import _ from 'lodash';
import { endpoint } from './App';
import Logo from './logoAssets/image.webp';
import DarkModeLogo from './logoAssets/image_inverted.webp';

const PostList = ({
  posts,
  setPosts,
  sortOption,
  setSortOption,
  setShowForm,
  setPostIdForDeletion,
  setDeleteConfirmation,
  setIsEditing,
  setEditPost,
  showForm,
  isEditing,
  editPost,
  newPost,
  handleInputChange,
  handleSubmit,
  showDeleteConfirmation,
  deletePost,
  toggleVote,
  postIdForDeletion,
  isPostListLoading,
  setIsPostListLoading,
  selectedIndustry,
  filterCountMap,
  toggleBuild
}) => {
    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const year = date.getFullYear();
    //     const month = date.getMonth() + 1; // getMonth() returns 0-11
    //     const day = date.getDate();

    //     return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    // };
    const navigate = useNavigate();
    const [isFirstTime, setIsFirstTime] = useState(localStorage.getItem("isFirstTime") === null);

    const industriesWithoutAll = ["Select", "Technology", "Medical", "Education", "Finance", "Retail", "Hospitality", "Other"];
    const problemCategories = ["Select", "Consumer", "Business", "Both"]
    const { user, loading, setLoading, isDarkMode } = useAuth();
    const defaultAvatar = "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'><path d='M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z'/></svg>";
    const handleClick = (e) => {
        e.preventDefault();
        if (user === null) {
            navigate('/login');
        }
        setShowForm(true)
      };
      
    const [errors, setErrors] = useState({ title: "", content: "", industryType: "" , problemCategory: ""});

    const fetchMorePosts = async (page = 0, industryType = 'All', sortOption = 'None') => {
        setLoading(true);
        try {
          const response = await axios.get(`${endpoint}/posts/industryType/${industryType}?sortOption=${sortOption}&page=${page}&size=10`, {
          });
          const data = response.data; // Access the data directly from the response      
          setPosts(data.content);
        } catch (error) {
          console.error('Error fetching posts:', error);
        } finally {
        setLoading(false);
        }
      };

    const isUserOwned = (postId) => {
        for (let id in user?.ownedPostsMap) {
            if (id === postId) {
                return true
            }
        }
        return false
    }
    
    const isEditPostFormValid = () => {
        let formErrors = { title: "", content: "", industryType: "", problemCategory: "" };
        let isValid = true;

        if (editPost.title === "") {
            formErrors.title = "EMPTY";
            isValid = false;
        } else if (editPost.title.length > 95) {
            formErrors.title = "TOO_LONG";
            isValid = false;
        }    
        if (editPost.content === "") {
            formErrors.content = "EMPTY";
            isValid = false;
        }
        
        if (editPost.industryType === "") {
            formErrors.industryType = "EMPTY";
            isValid = false;
        }
        if (newPost.problemCategory === "Select") {
            formErrors.problemCategory = "EMPTY";
            isValid = false;
        }
      
        setErrors(formErrors);
        return isValid;
    }

    const Pagination = ({ totalPages, currentPage, onPageChange }) => {
        return (
            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        className={`page-number ${index === Number(currentPage) ? 'active' : ''}`}
                        onClick={() => onPageChange(index)}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        );
    };

    const isNewPostFormValid = () => {
        let formErrors = { title: "", content: "", industryType: "", problemCategory: ""};
        let isValid = true;

        if (newPost.title === "") {
            formErrors.title = "EMPTY";
            isValid = false;
        } else if (newPost.title.length > 95) {
            formErrors.title = "TOO_LONG";
            isValid = false;
        }    
        if (newPost.content === "") {
            formErrors.content = "EMPTY";
            isValid = false;
        }
        
        if (newPost.industryType === "Select") {
            formErrors.industryType = "EMPTY";
            isValid = false;
        }

        if (newPost.problemCategory === "Select") {
            formErrors.problemCategory = "EMPTY";
            isValid = false;
        }
      
        setErrors(formErrors);
        return isValid;
      }

    const handleCommentsButtonClick = (post) => {
        const formattedTitle = post.title.toLowerCase().replace(/\s+/g, '-');
        navigate(`/post/${post.id}/${formattedTitle}`);
    };

    const handleWelcomeFormClose = () => {
        const welcomeComponent = document.querySelector('.welcome-form')
        if (welcomeComponent) {
            welcomeComponent.classList.remove('slide-in')
            welcomeComponent.classList.add('slide-out')
            // Wait for the slide-out animation to finish before updating state
            const handleAnimationEnd = () => {
                // Set local storage to indicate the user has visited before
                localStorage.setItem('isFirstTime', true);
                setIsFirstTime(false); // Update the state to hide the modal
                // Remove the event listener after it's called
                welcomeComponent.removeEventListener('animationend', handleAnimationEnd);
              };
          
              // Add the event listener using the named function
              welcomeComponent.addEventListener('animationend', handleAnimationEnd);
        }
    }

    const handlePostFormClose = (eventName) => {
        const postFormComponent = document.querySelector('.post-form')
        if (postFormComponent) {
            postFormComponent.classList.remove('slide-in')
            postFormComponent.classList.add('slide-out')
            // Wait for the slide-out animation to finish before updating state
            const handleAnimationEnd = () => {
                if (eventName === "cancel") {
                    setShowForm(false);
                } 
                setIsEditing(false);

                // Remove the event listener after it's called
                postFormComponent.removeEventListener('animationend', handleAnimationEnd);
              };
          
              // Add the event listener using the named function
              postFormComponent.addEventListener('animationend', handleAnimationEnd);
        }
    }

    const deleteFormClose = (eventName) => {
        const deleteFormComponent = document.querySelector('.delete-confirmation')
        if (deleteFormComponent) {
            deleteFormComponent.classList.remove('slide-in')
            deleteFormComponent.classList.add('slide-out')
            // Wait for the slide-out animation to finish before updating state
            const handleAnimationEnd = () => {
                if (eventName === "close") {
                    setDeleteConfirmation(false);
                } else if (eventName === "delete") {
                    deletePost(postIdForDeletion);
                    setDeleteConfirmation(false)
                }
                // Remove the event listener after it's called
                deleteFormComponent.removeEventListener('animationend', handleAnimationEnd);
              };
          
              // Add the event listener using the named function
              deleteFormComponent.addEventListener('animationend', handleAnimationEnd);
        }
    }

    const handleSubmitWithValidation = (e) => {
        e.preventDefault();
        if ((isEditing && isEditPostFormValid()) || (!isEditing && isNewPostFormValid())) {
            handleSubmit(e)
            handlePostFormClose("submit")
        } else {
            console.log(errors)
            if (errors.title === "TOO_LONG") {
                alert('Title cannot exceed 95 characters')
            } else {
                alert('Please fill in all fields')
            }
        }
    }
    const handlePageChange = (pageNumber) => {
        // Fetch posts for the selected page number
        localStorage.setItem('pageNumber', pageNumber);
        fetchMorePosts(pageNumber, selectedIndustry, sortOption);
    };

    return (
        <div className={`post-list-${loading ? 'greyed-out' : 'normal'}`}>
            <div className="post-list-header">
            {loading && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            <>
                {user ? (
                    <div className='input-toggler'>
                        <div className='header-avatar'>
                            <img src={user.avatarUrl} className="comment-avatar" />
                        </div>
                        <div className='post-text-area-wrapper' onClick={handleClick}>
                            <p className='problem-prompt'>"Share your problems here..."</p>
                        </div>
                    </div>
                ) : (
                    <div className='input-toggler'>
                        <div className='header-avatar'>
                            <img src={defaultAvatar} className="comment-avatar" />
                        </div>
                        <div className='post-text-area-wrapper' onClick={handleClick}>
                            <p className='problem-prompt'>"Share your problems here..."</p>
                        </div>
                    </div>
                )}
            </>
                <SortDropdown sortOption={sortOption} setSortOption={setSortOption} />
                </div>
                    {posts.map((post) => (
                        <div key={post.id} className="post">
                            <div className='post-headline'>
                                <Link to={`/profile/${post.ownerUsername}`}>
                                    <div className='post-avatar'>
                                        <img src={post.ownerAvatarUrl} className="comment-avatar" alt="User Avatar" />
                                    </div>
                                </Link>
                                <h3 className='list-post-username'>{post.ownerUsername}</h3>
                                <p className='list-post-misc'> <i class="fas fa-fire"></i> {post.demand}</p>
                                {/* <p className='list-post-misc'> <i class="fas fa-fire"></i> demand: ???</p> */}
                                <p className='list-post-misc'>|</p>
                                <p className='list-post-misc'><i class="fas fa-eye"></i> {post.views}</p>
                                <p className='list-post-misc'>|</p>
                                <p className='list-post-misc'><i class="fas fa-hammer" style={{ color: 'gray' }}></i> {post.builderCount}</p>

                                <p className='list-post-misc'>
                                {/* <i class="fas fa-calendar-alt"></i> {post.lastEditedDate ? `Last Edited: ${formatDate(post.lastEditedDate)}` : `${formatDate(post.createdDate)}`} */}
                                </p>
                                {
                                    post.problemCategory === 'Business' &&  <span className="business-problem-type-label">
                                    {post.problemCategory}
                                </span>
                                }
                                {
                                    post.problemCategory === 'Consumer' &&  <span className="consumer-problem-type-label">
                                    {post.problemCategory}
                                </span>
                                }
                                {
                                    post.problemCategory === 'Both' &&  (<>
                                    <span className="consumer-problem-type-label">
                                    {"Consumer"}
                                </span> <span className="business-problem-type-label">
                                    {"Business"}
                                </span>
                                    </>)
                                }
                                
                            </div>
                            {/* <h3 className='list-post-title'><Link to={`/post/${post.id}`}>{post.title}</Link></h3> */}
                            <h3 className='list-post-title'><Link to={`/post/${post.id}/${post.title.toLowerCase().replace(/\s+/g, '-')}`}>{post.title}
                        </Link></h3>
                            <pre className='preview-content'>{post.content}</pre>
                            {/* Intended to not be clickable so user know what link they are going */}
                            {/* <ContentWithLinks content={post.content}/> */}
                            <div className='post-button-row'>
                                <>
                                    {user && (
                                        <div className={`post-vote-button-div-${user.votedPosts?.includes(post.id) ? 'voted' : 'not-voted'}`}>
                                            <button
                                                onClick={() => {
                                                user.votedPosts.push(post.id);
                                                toggleVote(post.id, user.votedPosts?.includes(post.id));
                                                }}
                                            ><i class="fas fa-fire" style={{
                                                color: user.votedPosts?.includes(post.id) ? 'red' : 'gray' // Set color dynamically based on vote status
                                                }}></i></button>
                                            <button
                                                onClick={() => {
                                                user.builders.push(post.id);
                                                toggleBuild(post.id, user.votedPosts?.includes(post.id));
                                                }}
                                                ><i class="fas fa-hammer" style={{
                                                        color: user.builders?.includes(post.id) ? 'orange' : 'gray' // Set color dynamically based on vote status
                                                    }}></i>
                                            </button>
                                            <button
                                                onClick={() => {
                                                    handleCommentsButtonClick(post)
                                                }}>
                                                <i class="fas fa-comments" style={{ color: 'gray' }}></i> {post.commentCount}
                                            </button>
                                        </div>
                                    )}
                                    {!user && 
                                        <div className = {`post-vote-button-div-not-voted`}>
                                            <button
                                                onClick={() => {
                                                navigate('/login');
                                                }}
                                                ><i class="fas fa-fire" style={{color: 'gray' }}></i>
                                            </button>
                                            <button
                                            onClick={() => {
                                                navigate('/login');
                                                }}
                                                ><i class="fas fa-hammer" style={{ color: 'gray' }}></i>
                                            </button>
                                            <button
                                                onClick={() => {
                                                    handleCommentsButtonClick(post)
                                                }}>
                                                <i class="fas fa-comments" style={{ color: 'gray' }}></i> {post.commentCount}
                                            </button>
                                    </div>}
                                    
                                </>
                                {user?.ownedPostsMap && isUserOwned(post.id) && (
                                    <>
                                        <div className = "post-edit-button-div">
                                            <button className = "post-edit-button" onClick={() => {
                                            setIsEditing(true);
                                            setEditPost(post);
                                            }}><i className="fas fa-pen" style={{ color: '#3498db' }}></i> Edit</button>
                                        </div>
                                        <div className = "post-delete-button-div">
                                            <button className = "post-delete-button" onClick={() => {
                                            setPostIdForDeletion(post.id);
                                            setDeleteConfirmation(true);
                                            }}><i class="fas fa-trash"></i> Delete</button>
                                        </div>
                                        
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                    {(showForm || isEditing) && (
                        <div className="post-form">
                            <div className='post-form-inputs'>
                            <form onSubmit={handleSubmitWithValidation}>
                                <div className='title-row'>
                                    <div className='title-industry'>
                                    <img src={user.avatarUrl} className="post-form-avatar" />
                                        <div>
                                            <input 
                                                type="text" 
                                                name="title" 
                                                className="post-form-title" 
                                                value={isEditing ? editPost.title : newPost.title} 
                                                onChange={handleInputChange} 
                                                placeholder="Enter title here..." 
                                                />
                                            </div>
                                        <div>
                                            <select name='industryType' className="industryType" value={isEditing ? editPost.industryType : newPost.industryType} onChange={handleInputChange}>
                                                {industriesWithoutAll.map((industry) => (
                                                <option key={industry} value={industry}>{industry}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <select name='problemCategory' className="problemCategory" value={isEditing ? editPost.problemCategory : newPost.problemCategory} onChange={handleInputChange}>
                                                {problemCategories.map((problemCategory) => (
                                                <option key={problemCategory} value={problemCategory}>{problemCategory}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <textarea name="content"
                                    className='post-form-content'
                                    value={isEditing ? editPost.content : newPost.content}
                                    onChange={handleInputChange} 
                                    placeholder="Share your problems here..." ></textarea>
                                </div>
                                <div className='post-form-button-row'>
                                    <button type="button" className='post-form-cancel-button' onClick={() => {
                                        handlePostFormClose("cancel")
                                    }}>Cancel</button>
                                    <button type="submit" className='post-form-submit-button'>Submit</button>
                                </div>
                            </form>
                            </div>
                        </div>
                    )}
                    {showDeleteConfirmation && (
                        <>
                        <div className="modal-overlay"></div>
                        <div className="delete-confirmation">
                            <h2>Are you sure you want to delete this post?</h2>
                            <div className='delete-button-row'>
                                <button className='delete-button' type="button" onClick={() => {
                                    deleteFormClose("delete")  
                                }}>Delete</button>
                                <button className='delete-cancel-button' type="button" onClick={() => deleteFormClose("close")}>Close</button>
                            </div>
                        </div>
                        </>
                    )}
                    {!loading && isFirstTime && (
                        <>
                        <div className="modal-overlay"></div>
                            <div className="welcome-form">
                                <img src={isDarkMode ? DarkModeLogo : Logo} alt="Demand Metrics Icon" className="welcome-icon-image" />
                                <h2>Welcome to Demantrics</h2>
                                <p>Express your frustrations and watch them transform into new innovations for the future!</p>
                                <div className='delete-button-row'>
                                    <button className='enter-community-button' type="button" onClick={()=>handleWelcomeFormClose()}>Enter</button>
                            </div>
                        </div>
                        </>
                    )}
                    {/* <div> */}
                        {/* {loadingMorePosts && (
                            <div className="post-bottom-spinner"></div>
                        )} */}
                    {/* </div> */}
                    <Pagination totalPages={Math.ceil(filterCountMap[localStorage.getItem('selectedIndustry')] / 10) } currentPage={localStorage.getItem("pageNumber")} onPageChange={handlePageChange} />

                    <footer style={{ 
                        marginTop: '20px', 
                        textAlign: 'center', 
                        padding: '10px 0', 
                        fontSize: '14px' 
                    }}>
                        <div style={{ display: 'inline-block' }}>
                            <span style={{color:'#aaa'}}>Copyright &copy; 2024 Tsunacle Technology LLC</span>
                            <span style={{ margin: '0 10px' }}>|</span>
                            <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=0e8b61b5-aeb8-4cfd-a0a2-4a58a469fd73"
                             style={{ color:'#aaa', textDecoration: 'none' }}>Terms</a>
                            <span style={{ margin: '0 10px' }}>|</span>
                            <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=6e0f3aa4-1c3b-47bc-b643-2e0a5782f803" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                style={{ color:'#aaa', textDecoration: 'none' }}>
                                Privacy Policy
                            </a>
                        </div>
                    </footer>
        </div>
    );
};

export default PostList;