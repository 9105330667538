// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DarkModeToggle.css */
.toggle-container {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-right: 10px;
  }
  
  .checkbox {
    opacity: 0; /* Hide the default checkbox */
    position: absolute; /* Take it out of the layout */
    margin-right: 10px;
  }

  .dark-mode-row {
    display: flex; /* Use flexbox to control the layout */
    justify-content: space-between; /* Center horizontally */
    align-items: center;
    width: 100%; 
    margin-left: 10px;
}
  
  .label {
    width: 38px;
    height: 20px;
    background-color: #cccbcb; /* Dark mode background */
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .ball {
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: transform 0.3s ease-in-out;
  }
  
  .checkbox:checked + .label {
    background-color: #4d4d4d; /* Dark mode background */
  }
  
  .checkbox:checked + .label .ball {
    transform: translateX(18px); /* Move the ball to the right */
  }
`, "",{"version":3,"sources":["webpack://./src/DarkModeToggle.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,UAAU,EAAE,8BAA8B;IAC1C,kBAAkB,EAAE,8BAA8B;IAClD,kBAAkB;EACpB;;EAEA;IACE,aAAa,EAAE,sCAAsC;IACrD,8BAA8B,EAAE,wBAAwB;IACxD,mBAAmB;IACnB,WAAW;IACX,iBAAiB;AACrB;;EAEE;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB,EAAE,yBAAyB;IACpD,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,6CAA6C;EAC/C;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,sCAAsC;EACxC;;EAEA;IACE,yBAAyB,EAAE,yBAAyB;EACtD;;EAEA;IACE,2BAA2B,EAAE,+BAA+B;EAC9D","sourcesContent":["/* DarkModeToggle.css */\n.toggle-container {\n    display: flex;\n    align-items: center;\n    justify-content: right;\n    margin-right: 10px;\n  }\n  \n  .checkbox {\n    opacity: 0; /* Hide the default checkbox */\n    position: absolute; /* Take it out of the layout */\n    margin-right: 10px;\n  }\n\n  .dark-mode-row {\n    display: flex; /* Use flexbox to control the layout */\n    justify-content: space-between; /* Center horizontally */\n    align-items: center;\n    width: 100%; \n    margin-left: 10px;\n}\n  \n  .label {\n    width: 38px;\n    height: 20px;\n    background-color: #cccbcb; /* Dark mode background */\n    border-radius: 50px;\n    position: relative;\n    cursor: pointer;\n    transition: background-color 0.3s ease-in-out;\n  }\n  \n  .ball {\n    width: 16px;\n    height: 16px;\n    background-color: #ffffff;\n    border-radius: 50%;\n    position: absolute;\n    top: 2px;\n    left: 2px;\n    transition: transform 0.3s ease-in-out;\n  }\n  \n  .checkbox:checked + .label {\n    background-color: #4d4d4d; /* Dark mode background */\n  }\n  \n  .checkbox:checked + .label .ball {\n    transform: translateX(18px); /* Move the ball to the right */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
