import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import CommentForm from './CommentForm';
import {endpoint} from './App';
import { Link } from 'react-router-dom';

const CommentList = ({ post, setPost}) => {
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  const [comments, setComments] = useState(post.comments);
  const [replyingCommentId, setReplyingCommentId] = useState(null);
  const [replyContent, setReplyContent] = useState('');
  const { user, setUser } = useAuth();
  const [selectedReplies, setSelectedReplies] = useState([]);
  
  
  useEffect(() => {
    setComments(post.comments);
  }, [post]);

  const handleEdit = (commentId, comment) => {
    setEditingCommentId(commentId);
    setEditedContent(comment.content);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-11
    const day = date.getDate();

    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
};
  const toggleSelectedReplies = (commentId) => {
    setSelectedReplies(prevState => {
      if (prevState.includes(commentId)) {
        return selectedReplies.filter(id => id !== commentId)
      } else {
        return [...prevState, commentId]
      }
    }
  )
  }

  const handleSave = async (commentId) => {
    const token = localStorage.getItem('token');

    // Prepare the request body
    const requestBody = {
      postId: post.id,
      content: editedContent,
      commentId: commentId,
    };

    try {
      // Make the PUT request to the backend
      const headers = { 'Authorization': `Bearer ${token}` };
      const response = await axios.put(`${endpoint}/posts/comments/editComment`, requestBody, { headers });

      // Check if the response is successful
      if (response.status === 200) {
        // Update the local comments state
        const updatedComment = response.data;
        setComments(updatedComment);

        // Reset editing state after save
        setEditingCommentId(null);
        setEditedContent('');
        console.log("Comment updated successfully:", updatedComment);
      } else {
        console.error("Failed to update the comment:", response);
      }
    } catch (error) {
      console.error("Error updating the comment:", error);
    }
  };

  const handleReply = (commentId) => {
    setReplyingCommentId(commentId);
    setReplyContent('');
  };

  const handleDelete = async (commentId) => {
    const token = localStorage.getItem('token');
    const requestBody = {
      postId: post.id,
      content: '',
      commentId: commentId,
    };
    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
      };
      const response = await axios.delete(`${endpoint}/posts/comments/deleteComment`, {
        headers: headers,
        data: requestBody,
      });
      const userResponse = await axios.get(`${endpoint}/user`, { headers });
      if (response.data) {
        setComments(response.data);
        setUser(userResponse.data);
        localStorage.setItem('user', JSON.stringify(userResponse.data));
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const handleSaveReply = async (commentId) => {
    const token = localStorage.getItem('token');
    const requestBody = {
      postId: post.id,
      content: replyContent, // Ensure this is set correctly
      commentId: commentId,
    };

    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
      };

      const response = await axios.post(`${endpoint}/posts/comments/addNestedComment`, requestBody, { headers });

      if (response.data) {
        setComments(response.data.comments);
        const userResponse = await axios.get(`${endpoint}/user`, { headers });

        if (userResponse.data) {
          setUser(userResponse.data);
          localStorage.setItem('user', JSON.stringify(userResponse.data));
        }
      }
    } catch (error) {
      console.error('Error creating nested comment:', error);
    }

    setReplyingCommentId(null);
    setReplyContent('');
  };

  return (
    <div className="comment-list">
      {comments.length === 0 ? (
        <p>No solutions yet.</p>
      ) : (
        comments.map(comment => (
          <div key={comment.id} className="comment">
            <div className="comment-header">
              <Link Link to={`/profile/${comment.ownerUsername}`}>
                <img src={comment.ownerAvatarUrl} className="comment-avatar" />
              </Link>
              <div className="comment-author-info">
                <span className="comment-author">{comment.ownerUsername}</span>
                <span className="comment-time">{comment.lastEditedDate ? `Last Edited: ${formatDate(comment.lastEditedDate)}` : `${formatDate(comment.createdDate)}`}
                </span>
              </div>
            </div>
            {editingCommentId === comment.id ? (
              <textarea
                className="edit-textarea"
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value)}
              />
            ) : (
              <p className="comment-content">{comment.content}</p>
            )}
            <div className="comment-actions">
              {user && (
                <>
                  {user.ownedComments?.includes(comment.id) && (
                    <>
                    {editingCommentId === comment.id ? (
                      <button onClick={() => handleSave(comment.id)} className="comment-action-btn save">
                        Save</button>
                    ) : (
                      <button onClick={() => handleEdit(comment.id, comment)} className="comment-action-btn edit">
                          <i className="fas fa-pen" style={{ marginRight: '5px' }}></i> Edit
                      </button>                    
                    )}

                    {editingCommentId === comment.id ? (
                      <button onClick={() => setEditingCommentId("")} className="comment-action-btn cancel">
                        Cancel</button>
                    ) : (
                      <button onClick={() => handleDelete(comment.id)} className="comment-action-btn delete">Delete</button>
                    )}

                  </>
                  )}
                    <button onClick={() => handleReply(comment.id)} className="comment-action-btn reply">Reply</button>
                </>
              )}
              {comment.comments.length > 0 && (
                <button className="comment-action-btn view-replies" onClick={() => {toggleSelectedReplies(comment.id)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-chevron-{showReplies ? 'up' : 'down'}" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d={selectedReplies?.includes(comment.id) ? "M1.646 11.354a.5.5 0 0 0 .708 0L8 5.707l5.646 5.647a.5.5 0 0 0 .708-.708l-6-6a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 0 .708z" : "M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"}/>
                  </svg>
                  {`View ${comment.comments.length} ${comment.comments.length === 1 ? 'Reply' : 'Replies'}`}
              </button>
              )}
            </div>
            {replyingCommentId === comment.id && (
              <div className="reply-form">
                <textarea
                  className="reply-textarea"
                  value={replyContent}
                  onChange={(e) => setReplyContent(e.target.value)}
                />
                <div className="reply-buttons">
                  <button onClick={() => handleSaveReply(comment.id)} className="comment-action-btn save">Comment</button>
                  <button onClick={() => setReplyingCommentId("")} className="comment-action-btn cancel">Cancel</button>
                </div>
              </div>
            )}
            {comment.comments.length > 0 && selectedReplies?.includes(comment.id) && (
              <div className="nested-comments">
                {comment.comments.map(nestedComment => (
                  <div key={nestedComment.id} className={user?.username === comment.ownerUsername ? "nested-comment-user" : "nested-comment"}>
                    <div className="comment-header">
                      <img src={nestedComment.ownerAvatarUrl} className="comment-avatar" />
                      <div className="comment-author-info">
                        <span className="comment-author">{nestedComment.ownerUsername}</span>
                        <span className="comment-time">{comment.lastEditedDate ? `Last Edited: ${formatDate(comment.lastEditedDate)}` : `${formatDate(comment.createdDate)}`}</span>
                      </div>
                    </div>
                    {editingCommentId === nestedComment.id ? (
                      <textarea
                        className="edit-textarea"
                        value={editedContent}
                        onChange={(e) => setEditedContent(e.target.value)}
                      />
                    ) : (
                      <p className="comment-content">{nestedComment.content}</p>
                    )}
                    <div className="comment-actions">
                      {user && user.ownedComments?.includes(nestedComment.id) && (
                        <>
                          {editingCommentId === nestedComment.id ? (
                            <button onClick={() => handleSave(nestedComment.id)} className="comment-action-btn save">Save</button>
                          ) : (
                            <button onClick={() => handleEdit(nestedComment.id, nestedComment)} className="comment-action-btn edit">Edit</button>
                          )}
                          {editingCommentId === nestedComment.id ? (
                      <button onClick={() => setEditingCommentId("")} className="comment-action-btn cancel">
                        Cancel</button>
                        ) : (
                          <button onClick={() => handleDelete(nestedComment.id)} className="comment-action-btn delete">Delete</button>
                        )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default CommentList;