import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate} from 'react-router-dom';
import CommentList from './CommentList';
import CommentForm from './CommentForm';
import { useAuth } from './AuthContext';
import PostDetailHeader from './PostDetailHeader';
import { endpoint } from './App';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const PostDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user, setUser } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({ title: false, content: false, industryType: false });
  const [editPost, setEditPost] = useState({
    id: '',
    title: '',
    content: '',
    industryType: '',
    demand: 0,
  });
  const initialPostState = {
    id: '',
    title: '',
    content: '',
    industryType: '',
    demand: 0,
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-11
    const day = date.getDate();

    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
};

  const isPostFormValid = () => {
    let formErrors = { title: false, content: false, industryType: false };
    let isValid = true;
    
    if (!editPost.title) {
        formErrors.title = true;
        isValid = false;
    }
    
    if (!editPost.content) {
        formErrors.content = true;
        isValid = false;
    }
    
    if (!editPost.industryType) {
        formErrors.industryType = true;
        isValid = false;
    }

    setErrors(formErrors);
    return isValid;
}

const industriesWithoutAll = ["", "Medical", "Technology", "Education", "Finance", "Retail", "Hospitality", "Other"];

  const handleSubmitWithValidation = (e) => {
    e.preventDefault();
    if (isPostFormValid()) {
        handleSubmit(e)
        handleEditFormClose()
    } else {
        alert('Please fill in all fields')
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
      setEditPost({...editPost, [name]: value })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      const response = await axios.put(`${endpoint}/posts/editPost`, editPost, { headers });
      fetchPost();
    } catch (error) {
      console.error("Error submitting post:", error);
    }
    setEditPost(initialPostState);
  };
  
  const fetchPost = async () => {
    const token = localStorage.getItem('token');
    const tmpUser = JSON.parse(localStorage.getItem('user'))
    try {
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      const response = await axios.put(`${endpoint}/posts/viewPost`, {
        postId: id,
        duaId: tmpUser ? tmpUser.id : localStorage.getItem('DUAID')
      }, { headers });
      if (response && response.data) {
        setPost(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching post details:', error);
    }
  };
  const handleAddComment = async (comment) => {
    try {
      const token = localStorage.getItem('token');
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      const response = await axios.post(`${endpoint}/posts/comments/addComment`,
       comment, { headers }
       );
      const userResponse = await axios.get(`${endpoint}/user`, { headers });
      if (response && response.data) {
        setPost(response.data);
        setUser(userResponse.data)
        localStorage.setItem('user', JSON.stringify(userResponse.data))
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };
  useEffect(() => {
    console.log("HERE")
    fetchPost();
  }, [id, user]);

  const handleEditFormClose = () => {
    const postFormComponent = document.querySelector('.post-form')
    if (postFormComponent) {
        postFormComponent.classList.remove('slide-in')
        postFormComponent.classList.add('slide-out')
        // Wait for the slide-out animation to finish before updating state
        const handleAnimationEnd = () => {
          console.log("GOT HERERER")
            // Remove the event listener after it's called
            postFormComponent.removeEventListener('animationend', handleAnimationEnd);
            setIsEditing(false)
          };
          // Add the event listener using the named function
          postFormComponent.addEventListener('animationend', handleAnimationEnd);
    }
}

  const avatarSvg = 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>';
  const upvoteSvg = 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 4l-8 8h6v8h4v-8h6l-8-8z"/></svg>';

  const handleUpvote = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      // Assuming you have an API endpoint for voting
      const requestBody = {
        "postId": post.id,
        "commentId": "",
        "isUpVote" : true
      }
      const response = await axios.put(`${endpoint}/posts/increaseDemand`, requestBody, {
        headers
      });
      localStorage.setItem('user',JSON.stringify(response.data.user))
      setUser(response.data.user)
    } catch (error) {
      console.error('Error voting:', error);
    }
  };

  if (loading) return <>
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
                </>; // Show the spinner while loading


  if (!post) return <div>Post not found</div>; // Handle case where post is not found

  return (
    <article className="post-detail-page">
      <Helmet>
        {/* Page Title */}
        <title>{post.title} - Demantrics</title>

        {/* Meta Description */}
        <meta name="description" content={post.content.substring(0, 150)} />

        {/* Open Graph Tags for Social Media Sharing */}
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.content.substring(0, 150)} />

        {/* Use post-specific image or fallback image if not available */}
        <meta property="og:image" content={post.ownerAvatarUrl || 'https://demantrics.io/default-image.jpg'} />

        {/* Open Graph URL */}
        <meta property="og:url" content={`https://www.demantrics.io/post/${post.id}/${encodeURIComponent(post.title?.replace(/\s+/g, '-'))}`} />

        {/* Canonical Link */}
        <link rel="canonical" href={`https://www.demantrics.io/post/${post.id}/${encodeURIComponent(post.title?.replace(/\s+/g, '-'))}`} />

        {/* Open Graph Type (optional) */}
        <meta property="og:type" content="article" />

        {/* Twitter Card Metadata (Optional) */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.content.substring(0, 150)} />
      </Helmet>

      <div className='post-detail'>
      <header>
         <PostDetailHeader post={post} isEditing={isEditing} setIsEditing={setIsEditing} setEditPost={setEditPost}/>
      </header>
        <div className='head-post'>
          <div className="comment-header">
            <div className='comment-header-avatar'>
            <div className='post-headline'>
              <Link to={`/profile/${post.ownerUsername}`}>
                <div className='post-avatar'>
                  <img src={post.ownerAvatarUrl} className="comment-avatar" />
                </div>
              </Link>
              <h3 className='list-post-username'>{post.ownerUsername}</h3>
              <p className='list-post-misc'><i class="fas fa-fire"></i> demand: {post.demand}</p>
              <p className='list-post-misc'>|</p>
              <p className='list-post-misc'><i class="fas fa-eye"></i> views: {post.views}</p>
              <p className='list-post-misc'>|</p>
              <p className='list-post-misc'><i class="fas fa-hammer" style={{ color: 'gray' }}></i> builders: {post.builderCount}</p>
              <p className='list-post-misc'>|</p>
              <p className='list-post-misc'>
              <i class="fas fa-calendar-alt"></i> {post.lastEditedDate ? `Last Edited: ${formatDate(post.lastEditedDate)}` : `${formatDate(post.createdDate)}`}
              </p>
              </div>
            </div>
          </div>
          <div className='post-detail-content'>
            <pre>{post.content}</pre>
            <div className='post-detail-content-demand-row'>
            <p className="user-question">Facing the problem described above?</p>
            <div className='post-button-row'>
              <>{user && 
                  <div className = {`post-vote-button-div-${user?.votedPosts?.includes(post.id) ? 'voted' : 'not-voted'}`}>
                    <button
                        onClick={() => {
                            handleUpvote(post.id, user?.votedPosts?.includes(post.id));
                        }}
                        ><i class="fas fa-fire" style={{
                          color: user?.votedPosts?.includes(post.id) ? 'red' : 'gray' // Set color dynamically based on vote status
                          }}></i>
                    </button>
              </div>
              }
              {
                !user && 
                  <div className = {`post-vote-button-div-not-voted`}>
                    <button
                        onClick={() => {
                          navigate('/login');
                        }}
                        >
                        <i class="fas fa-fire" style={{
                          color: user?.votedPosts?.includes(post.id) ? 'red' : 'gray' // Set color dynamically based on vote status
                          }}></i>
                    </button>
              </div>
              }
              </>
            </div>
            </div>
          </div>
        </div>
        {(isEditing) && (
          <div className="post-form">
              <div className='post-form-inputs'>
              <form onSubmit={handleSubmitWithValidation}>
                  <div className='title-row'>
                      <div className='title-industry'>
                      <img src={user.avatarUrl} className="post-form-avatar" />
                          <div>
                              <input 
                                  type="text" 
                                  name="title" 
                                  className="post-form-title" 
                                  value={editPost.title} 
                                  onChange={handleInputChange} 
                                  placeholder="Enter title here..." 
                                  />
                              </div>
                          <div>
                              <select name='industryType' className="industryType" value={editPost.industryType} onChange={handleInputChange}>
                                  {industriesWithoutAll.map((industry) => (
                                  <option key={industry} value={industry}>{industry}</option>
                                  ))}
                              </select>
                          </div>
                      </div>
                  </div>
                  <div>
                      <textarea name="content"
                      className='post-form-content'
                      value={editPost.content}
                      onChange={handleInputChange} 
                      placeholder="Share your problems here..." ></textarea>
                  </div>
                  <div className='post-form-button-row'>
                      <button type="button" className= 'post-form-cancel-button'onClick={() => {
                          handleEditFormClose()
                      }}>Cancel</button>
                      <button type="submit" className='post-form-submit-button'>Submit</button>
                  </div>
              </form>
              </div>
          </div>
        )}
        <div className="comment-row">
          <div className='comment-title'>Comments/Products:</div>
          {user && <CommentForm postId={post.id} onAddComment={handleAddComment} />}
        </div>
        <CommentList post={post} setPost={setPost} />
      </div>
    </article>
  );
};

export default PostDetail;