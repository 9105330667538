import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './LoginPage.css';
import { Link } from 'react-router-dom';
import { endpoint } from './App';
import Logo from './logoAssets/image.webp';
import DarkModeLogo from './logoAssets/image_inverted.webp';


function LoginPage() {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // New loading state
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const navigate = useNavigate();
  const { setUser, isDarkMode } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    // Clear local storage before login
    // localStorage.clear();
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    e.preventDefault();
    if (!credentials.username || !credentials.password) {
      setError('Please fill in all fields');
      return;
    }

    // Set loading to true when the form is submitted
    setIsLoading(true);
    try {
      const response = await axios.post(`${endpoint}/auth/login`, credentials);
      const { token, user } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
      navigate('/');
    } catch (error) {
      setError('Login failed: username or password incorrect');
    } finally {
      setIsLoading(false); // Stop loading once request completes
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <img src={isDarkMode ? DarkModeLogo : Logo} alt="Demand Metrics Icon" className="login-icon" />
        <h2>Demantrics</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>Username</label>
            <input
              type="text"
              name="username"
              className='login-input'
              value={credentials.username}
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                className="login-input"
                value={credentials.password}
                onChange={handleChange}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '-10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                {showPassword ? (
                  <i className="fas fa-eye-slash" style={{ color: 'lightblue' }}></i>  // Eye with a slash for hiding
                ) : (
                  <i className="fas fa-eye" style={{ color: 'lightblue' }}></i>  // Eye icon for showing
                )}
              </button>
            </div>
          </div>
          {error && <p className="error">{error}</p>}
          {/* Loading Spinner Logic */}
          <button type="submit" className="login-button" disabled={isLoading}>
            {isLoading ? (
              <>
                Signing In...
              </>
            ) : (
              'Sign In'
            )}
          </button>
        </form>
        <div className="login-footer">
          <Link to="/forgot-password">Forgot password</Link>
          <Link to="/register">Sign up</Link>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;