// DarkModeToggle.js
import React, { useState } from 'react';
import './DarkModeToggle.css'; // Import the CSS file
import { useAuth } from './AuthContext';


const DarkModeToggle = () => {

    const { isDarkMode, setIsDarkMode } = useAuth();

    const handleToggle = () => {
        setIsDarkMode((prevMode) => !prevMode);
      };

    return (
        <div className="toggle-container">
            <div className='dark-mode-row'>
                <p>Dark Mode</p>
                <input
                    type="checkbox"
                    className="checkbox"
                    id="dark-mode-toggle"
                    checked={isDarkMode}
                    onChange={handleToggle}
                />
                 <label htmlFor="dark-mode-toggle" className="label">
                 <div className="ball"></div>
                    </label>
            </div>
           
            
        </div>
    );
};

export default DarkModeToggle;