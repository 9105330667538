import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './RegisterPage.css'; // Make sure to import the CSS file
import { endpoint } from './App';
import Logo from './logoAssets/image.webp';
import DarkModeLogo from './logoAssets/image_inverted.webp';
import { useAuth } from './AuthContext';

function RegisterPage() {
  const [formData, setFormData] = useState({ username: '', password: '', confirmPassword: '', email: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state for the button
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const {isDarkMode} = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Prevent submission if loading is already in progress
    if (isLoading) return;
    
    // Basic validations
    if (!formData.username || !formData.password || !formData.confirmPassword || !formData.email) {
      setError('Please fill in all fields');
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsLoading(true); // Set loading to true when submission starts
    try {
      await axios.post(`${endpoint}/auth/register`, formData);
      setSuccess("Verification email sent! Check spam/junk too!");
      setError('');
      setIsLoading(false); // Stop loading after successful registration
    } catch (error) {
      setError(error.response?.data || "Registration failed");
      setSuccess('');
      setIsLoading(false); // Stop loading after error
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="register-container">
      <div className="register-card">
        <img src={isDarkMode ? DarkModeLogo : Logo} alt="Demand Metrics Icon" className="login-icon" />

        <h2>Demantrics</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>Username</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '-10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                {showPassword ? <i className="fas fa-eye-slash" style={{ color: 'lightblue' }}></i> : <i className="fas fa-eye" style={{ color: 'lightblue' }}></i>}
              </button>
            </div>
          </div>
          <div className="input-group">
            <label>Confirm Password</label>
            <div style={{ position: 'relative' }}>
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              <button
                type="button"
                onClick={toggleConfirmPasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '-10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                {showConfirmPassword ? <i className="fas fa-eye-slash" style={{ color: 'lightblue' }}></i> : <i className="fas fa-eye" style={{ color: 'lightblue' }}></i>}
              </button>
            </div>
          </div>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          {success && <p className='success'>{success}</p>}
          {error && <p className="error">{error}</p>}
          
          {/* Button with loading spinner */}
          <button type="submit" className="register-button" disabled={isLoading}>
            {isLoading ? (
              <>
                Registering...
              </>
            ) : (
              'Register'
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default RegisterPage;