import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './GlobalHeader.css';
import ProfilePage from './ProfilePage'
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import DarkModeLogo from './logoAssets/image_inverted.webp';
import Logo from './logoAssets/image.webp';

import DarkModeToggle from './DarkModeToggle';

function GlobalHeader() {
  const { user, setUser, isDarkMode} = useAuth();
  const navigate = useNavigate();

  const handleSignout = (e) => {
    e.preventDefault()
    // localStorage.clear();
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    setUser(null);
    navigate('/');
  };

  const avatarSvg = 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>';

  return (
    <header className="global-header">
      <div className="header-content">
        <div className="logo-container">
          <Link to="/" className="main-logo">
            <img src={isDarkMode ? DarkModeLogo : Logo} alt="Demand Metrics Icon" className="icon-image" />
            <span>Demantrics</span>
          </Link>
        </div>
        
        <div className="user-actions">
          {user ? (
            <>
              <div className="dropdown">
                <img src={user.avatarUrl} className="default-avatar" alt=''/>
                <div className="dropdown-content">
                  <Link to={`/profile/${user.username}`} element={<ProfilePage />}>Profile </Link>
                  <Link onClick={handleSignout}>Sign Out</Link>
                  <DarkModeToggle></DarkModeToggle>
                </div>
              </div>
            </>
          ) : (
            <div className="dropdown">
              <img src={avatarSvg} className="default-avatar" alt=''/>
              <div className="dropdown-content">
                <Link to="/login">Sign in</Link>
                <Link to="/register">Sign up</Link>
                <DarkModeToggle></DarkModeToggle>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default GlobalHeader;