import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {endpoint} from './App';
import './PostRankPane.css'; // Make sure to create this CSS file
import { Link, redirect, useNavigate} from 'react-router-dom';

const PostRankPane = () => {

    const [postRankPaneLoading, setPostRankPaneLoading] = useState(true)
    const [postRankList, setPostRankList] = useState([])

    const fetchRankings = async () => {
        try {      
            const response = await axios.get(`${endpoint}/ranking/getPostRanking`);
            setPostRankList(response.data)
            console.log(response)
          } catch (error) {
            console.error("Error fetching rankings:", error);
          } finally {
            setPostRankPaneLoading(false);
          }
    }

    useEffect(() => {
        fetchRankings()
    }, []) 

    if (postRankPaneLoading) {
        return <div className = "post-rank-pane"></div>
    }

    return (
        <div className="post-rank-pane">
            <h3 className="monthly-ranking-title">🏆 Monthly Ranking</h3>

            <div className="post-rank-list">
            {postRankList.map((post, index) => (
                <div className="post-rank-item" key={post.id}>
                    {/* Rank Number */}
                    <div
                        className="rank-number"
                        style={{
                            color:
                                index === 0 ? 'gold' :
                                index === 1 ? 'silver' :
                                index === 2 ? '#CD7F32' : '#777' // Bronze color for third, default for others
                        }}
                    >
                        {index + 1}
                    </div>

                    {/* Post Details */}
                    <div className="post-rank">
                        {/* Post Title (Username or Title) */}
                        <div className='post-rank-title'>
                            <Link
                                to={`/post/${post.id}/${post.title.toLowerCase().replace(/\s+/g, '-')}`}
                                className="post-title-link"
                            >
                                {post.title}
                            </Link>
                        </div>
                        {/* Post Demand */}
                        <div className="post-rank-demand"><i class="fas fa-fire" style={{
                          color: 'gray' // Set color dynamically based on vote status
                          }}></i> Demand: {post.demand}</div>
                    </div>
                </div>
            ))}
            </div>
        </div>
    );
};

export default PostRankPane;